function reformatnews(target = ".msl-news", defaultimage = "https://code.derbyunion.co.uk/usdev/images/us-scribble.svg") {
  const mslnews = [].slice.call(document.querySelectorAll(".msl-news .msl-item"))
  const ournews = mslnews.map(article => {
    const title = article.querySelector(".msl-title").innerText
    const link = article.querySelector("h3 a").href
    const org = [article.dataset.mslOrganisationId, article.querySelector(".msl-organisation").innerText]
    const image = article.querySelector(".msl-image img") ? article.querySelector(".msl-image img").src.split("?") : [defaultimage]
    const lead = article.querySelector('.msl-leader') ? article.querySelector('.msl-leader').innerText : null
    const date = Date.parse(article.querySelector('.msl-pubdate').innerText)
    const mslimageoptions = "?thumbnail_width=460&amp;thumbnail_height=0&amp;resize_type=ResizeWidth"

    return {
      title,
      link,
      org,
      image: image[0] + mslimageoptions,
      lead,
      date
    }
  })

  function timeAgo(time) {
    const daysago = Math.round(moment.duration(moment().startOf('day') - time).asDays())
    switch (daysago) {
      case 0:
        return "today"
      case 1:
        return "yesterday"
      default:
        return `${daysago} days ago`
    }
  }

  function showOrg(orgname) {
    return true
    /*
    const noshow = [
      'Welcome',
      'Union of Students'
    ]

    if (noshow.indexOf(orgname.toString().split(/,(.+)/)[1]) !== -1) {
      return false
    }
    return true
    */
  }

  function reformatNews(newslist) {
    let html = ''
    newslist.forEach(article => {
      html += `

      <div class="newslist-item  swiper-slide  news-card ${Math.round(moment.duration(moment().startOf('day') - article.date).asDays()) > 60 ? ' news-old' : ''}" data-orgid="${article.org[0]}">
        <a class="news-card__link" href="${article.link}">
          <div class="news-card__image" style="background-image: url('${article.image}')">
            <div></div>
          </div>
          ${showOrg(article.org) ? `<div class="news-card__org">${article.org.toString().split(/,(.+)/)[1]} </div>` : ''}
          <div class="news-card__content">
            <div class="news-card__title">${article.title}</div>
            <div class="news-card__lead">${article.lead}</div>
            <div class="news-card__meta">
              <p>posted <time datetime="${article.date}" title="${article.date}">${timeAgo(article.date)}</time></p>
            </div>
          </div>
        </a>
      </div>

      `
    })
    return html
  }

  document.querySelector(target).innerHTML = reformatNews(ournews)
}
